<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Price Changes"
      :showback="true"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Records
          </h2>
        </v-col>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.old_price="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.new_price="{value}">
              {{ formatPrice(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VehicleService from '@/services/VehicleService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'VehiclePriceChanges',
    mixins: [formatterMixin],
    data () {
      return {
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Date Changed', value: 'created_at' },
          { text: 'Old Price', value: 'old_price', align: 'center' },
          { text: 'New Price', value: 'new_price', align: 'center' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_vehicle_price_changes.xls`
      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadPriceChanges()
      },
    },
    created () {
      this.loadPriceChanges()
    },
    methods: {
      loadPriceChanges: function () {
        this.items = []
        this.loading = true
        VehicleService.getPriceChanges(this.$route.params.vehicle_id)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
    },
  }
</script>
